import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { isCollapsed: Boolean }

    click(event) {
        this.isCollapsedValue = !this.isCollapsedValue
        const content = this.isCollapsedValue
          ? '<i class="filter-eye-icon fa fa-eye"></i> Mostrar Filtros'
          : '<i class="filter-eye-icon fa fa-eye-slash"></i> Ocultar Filtros'
        this.element.innerHTML = content
    }

}