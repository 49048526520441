import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
    addEventListener("turbo:before-fetch-request", (event) => {
      if (event.target instanceof HTMLFormElement) {
        const { fetchOptions: { method, headers } } = event.detail
        console.log()

        if (/get/i.test(method)) {
          headers.Accept = [ "text/vnd.turbo-stream.html", headers.Accept ].join(", ")
        }
      }
    })
  }
}
