import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination"
export default class extends Controller {
  static values = {
    url: String,
    page: Number
  }

  initialize() {
    this.click = this.click.bind(this)
    this.pageValue = this.pageValue || 1
  }

  connect() {
    document.addEventListener("click", this.click)
  }

  click() {
    if (this.lastPageReached) this.fetchNewPage()
  }

  async fetchNewPage() {
    const url = new URL(this.urlValue)
    url.searchParams.set("page", this.pageValue)

    await get(url.toString(), { responseKind: "turbo-stream" })

    this.pageValue += 1
  }
}
