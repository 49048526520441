import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tickets"
export default class extends Controller {
  connect() {
  }

  toggleHasWasteInput(event) {
    event.preventDefault()
    event.stopPropagation()
    const { value } = event.target

    const disabled = ![1, 4].includes(Number(value))
    document.querySelectorAll('.has-waste').forEach((el) => {
      if (disabled) el.setAttribute("disabled", true)
      else el.removeAttribute("disabled")
    })
  }
}
