import { Controller } from "@hotwired/stimulus"
import { Carousel, Modal } from "bootstrap"

// Connects to data-controller="carousel"
export default class extends Controller {
  connect() {
    this.carousel = new Carousel(this.element, { interval: false })
  }

  disconnect() {
  }

  next() {
    this.carousel.next()
  }

  previous() {
    this.carousel.prev()
  }

  zoomIn() {
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  zoomOut() {
    this.modal.hide()
  }
}
