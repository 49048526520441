import { Controller } from "@hotwired/stimulus"
import Viewer from "viewerjs"

// Connects to data-controller="image-viewer"
export default class extends Controller {
  connect() {
    this.viewer = new Viewer(this.element, {
      inline: true,
      minHeight: 300,
      minWidth: "auto",
      initialViewIndex: 0
    });
    this.viewer.show()
  }

  disconnect() {
    this.viewer.hide()
  }
}
