import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo"
export default class extends Controller {
  static values = { url: String }

  initialize() {
    this.element.setAttribute("data-action", "click->turbo#click")
  }

  click(event) {
    event.preventDefault()
    this.url = this.element.getAttribute("href")
    fetch(this.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(res => res.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
