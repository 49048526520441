import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="selectable"
export default class extends Controller {
  static values = {
    model: String,
    selectedItems: Array,
    searchFields: Array,
    valueField: String,
    sortedField: String,
    sortedDirection: 'desc' | 'asc'
  }

  connect() {
    this._initialize()
  }

  disconnect() {
    if (this.selectable) this.selectable.destroy()
  }

  _initialize() {
    if (this.element) {

      if (!this.sortedFieldValue || this.sortedFieldValue === '') {
        this.sortedFieldValue = 'name'
      }

      this.selectable = new TomSelect(
        this.element,
        {
          plugins: ["clear_button"],
          valueField: this.valueFieldValue || 'id',
          // labelField: 'full_name', -> Creo que no sirve pa nada porque usa el que trae nativo
          searchField: this.searchFieldsValue,
          selectOnTab: true,
          placeholder: this.element.getAttribute("placeholder"),
          preload: true,
          create: false,
          hideSelected: true,
          sortField: { field: this.sortedFieldValue, direction: this.sortedDirectionValue || 'asc' },
          items: this.selectedItemsValue || [],
          render: {
            option: (data, escape) => {
              return `<div id="data-${escape(data.id)}">${escape(data.text)}</div>`
            },
            item: (data, escape) => {
              return `<div id="data-${escape(data.id)}">${escape(data.text)}</div>`
            }
          }
        }
      )
    }
  }
}
