import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="selectable"
export default class extends Controller {
  static values = {
    selectedItems: Array,
    valueField: String,
    sortedField: String,
    sortedDirection: 'desc' | 'asc'
  }

  connect() {
    this._initialize()
  }

  disconnect() {
    if (this.selectable) this.selectable.destroy()
  }

  _initialize() {
    if (this.element) {

      this.selectable = new TomSelect(
        this.element,
        {
          plugins: ["clear_button"],
          valueField: this.valueFieldValue || 'id',
          searchField: this.searchFieldsValue,
          selectOnTab: true,
          placeholder: this.element.getAttribute("placeholder"),
          preload: true,
          create: false,
          hideSelected: true,
          sortField: { field: this.sortedFieldValue, direction: this.sortedDirectionValue || 'asc' },
          items: this.selectedItemsValue || []
        }
      )
    }
  }
}
